@font-face
	font-family: 'Objektiv'
	src: local('Objektiv Regular'), local('Objektiv-Regular'), url('/assets/ObjektivMk1-Regular.woff2') format('woff2'), url('/assets/ObjektivMk1-Regular.woff') format('woff')
	font-weight: 400
	font-style: normal

@mixin display-xl
	font-weight: 700
	font-size: 56px
	line-height: 64px

@mixin display-l
	font-weight: 700
	font-size: 48px
	line-height: 56px

@mixin display-m
	font-weight: 600
	font-size: 40px
	line-height: 48px

@mixin display-s
	font-weight: 500
	font-size: 32px
	line-height: 40px

@mixin headers-h1
	font-weight: 600
	font-size: 32px
	line-height: 40px

@mixin headers-h2
	font-weight: 600
	font-size: 28px
	line-height: 32px

@mixin headers-h3
	font-weight: 600
	font-size: 24px
	line-height: 28px

@mixin headers-h4
	font-weight: 600
	font-size: 20px
	line-height: 24px

@mixin subheaders-m-600
	font-weight: 600
	font-size: 18px
	line-height: 24px

@mixin subheaders-l
	font-weight: 500
	font-size: 20px
	line-height: 26px

@mixin subheaders-l-600
	font-weight: 600
	font-size: 20px
	line-height: 26px

@mixin subheaders-m
	font-weight: 500
	font-size: 18px
	line-height: 24px

@mixin subheaders-m-regular
	font-weight: 400
	font-size: 18px
	line-height: 24px

@mixin subheaders-s
	font-weight: 500
	font-size: 16px
	line-height: 22px

@mixin subheaders-s-600
	font-weight: 600
	font-size: 16px
	line-height: 22px

@mixin caption-regular
	font-weight: 400
	font-size: 12px
	line-height: 16px

@mixin content-m-regular
	font-weight: 400
	font-size: 12px
	line-height: 16px

@mixin content-m-500
	font-weight: 500
	font-size: 14px
	line-height: 20px

@mixin content-m-400
	font-weight: 400
	font-size: 14px
	line-height: 20px

@mixin content-12
	font-weight: 400
	font-size: 12px
	line-height: 16px

@mixin content-l-regular
	font-weight: normal
	font-size: 16px
	line-height: 24px

@mixin content-l-500
	font-weight: 500
	font-size: 16px
	line-height: 24px

@mixin content-s-500
	font-weight: 500
	font-size: 12px
	line-height: 16px

@mixin content-s-400
	font-weight: 400
	font-size: 12px
	line-height: 16px

@mixin caption-medium
	font-weight: 500
	font-size: 12px
	line-height: 16px

@mixin long-l-medium
	font-weight: 500
	font-size: 16px
	line-height: 22px

@mixin long-l-regular
	font-weight: 400
	font-size: 16px
	line-height: 22px

@mixin long-m-medium
	font-weight: 500
	font-size: 14px
	line-height: 20px

@mixin long-m-regular
	font-size: 14px
	line-height: 20px

@mixin long-s-medium
	font-weight: 500
	font-size: 13px
	line-height: 18px

@mixin long-s-regular
	font-weight: 400
	font-size: 13px
	line-height: 18px

@mixin short-l-medium
	font-weight: 500
	font-size: 16px
	line-height: 20px

@mixin short-l-regular
	font-weight: 400
	font-size: 16px
	line-height: 20px

@mixin short-m-medium
	font-weight: 500
	font-size: 14px
	line-height: 18px

@mixin short-m-regular
	font-weight: 400
	font-size: 14px
	line-height: 18px
	letter-spacing: -0.15px

@mixin short-s-medium
	font-weight: 500
	font-size: 13px
	line-height: 16px

@mixin short-s-regular
	font-weight: 400
	font-size: 13px
	line-height: 16px

@mixin control-l
	font-weight: 500
	font-size: 16px
	line-height: 24px
	letter-spacing: -0.25px

@mixin control-m
	font-weight: 400
	font-size: 14px
	line-height: 20px

@mixin control-s
	font-weight: 400
	font-size: 14px
	line-height: 16px

@mixin control-xs
	font-weight: 400
	font-size: 13px
	line-height: 16px

@mixin overline-l
	font-weight: 500
	font-size: 12px
	line-height: 14px

@mixin overline-m
	font-weight: 500
	font-size: 11px
	line-height: 14px

@mixin overline-s
	font-weight: 500
	font-size: 10px
	line-height: 12px
