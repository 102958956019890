.table
	+long-m-regular
	width: 100%
	table-layout: fixed

	.cdk-header-cell, .cdk-cell
		padding: 18px 8px
		text-align: left
		vertical-align: middle
		border-bottom: 1px solid $c-white-08
		word-break: break-word

	.cdk-header-cell
		background-color: $c-white-08

	.cdk-row:hover
		.cdk-cell
			background-color: $c-second-bg
