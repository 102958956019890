@import "mixins"

.cdk-dialog-container
	background-color: $c-black-second
	border-radius: 16px
	padding: 28px

.cdk-overlay-dark-backdrop
	background: black !important
	opacity: 0.8 !important

.transparent-container .cdk-dialog-container
	background: transparent
	padding: 0
	border-radius: initial

.modal-dialog
	max-height: 90vh

	.cdk-dialog-container
		overflow-y: auto
