@import 'typographics'
@import '~@angular/cdk/text-field-prebuilt.css'

$c-white-100: white
$c-white-80: rgba(white, 0.80)
$c-white-60: rgba(white, 0.60)
$c-white-40: rgba(white, 0.40)
$c-white-32: rgba(white, 0.32)
$c-white-24: rgba(white, 0.24)
$c-white-16: rgba(white, 0.16)
$c-white-12: rgba(white, 0.12)
$c-white-10: rgba(white, 0.1)
$c-white-08: rgba(white, 0.08)

$c-grey-08: rgba(#848484, 0.08)

$c-black-main: #121212
$c-black-80: rgba($c-black-main, 0.8)
$c-black-second: #1C1C1C
$c-ameetee-gray: #3B3B3B
$c-second-bg: #323232

$c-system-error: #FF4545
$c-system-error-10: rgba(#ff4545, 0.1)

$c-green-main: #40BA00
$c-green-main-10: rgba(#40BA00, 0.1)
$c-green-hover: #38a100
$c-green-press: #329000
$c-green-disabled: #40BA0051
$c-system-success: #18BF7D
$c-system-success-10: rgba(#18BF7D, 0.1)

$c-green-background: #E3F9D7

$c-brand: #B1FF83
$c-brand-10: rgba($c-brand, 0.1)
$c-orange: #FF9500
$c-orange-10: rgba($c-orange, 0.1)

$c-border-gray: #525050
$c-light-grey: #CFCFCF19
$c-yellow: #EABD3C
$c-yellow-10: rgba($c-yellow, 0.1)
$c-magenta: #DC4BB3
$c-magenta-10: rgba($c-magenta, 0.1)
$c-cyan: #1DE5BC
$c-cyan-10: rgba($c-cyan, 0.1)

$sidebar-width: 256px

@mixin scrollbar
	&::-webkit-scrollbar
		width: 5px

	&::-webkit-scrollbar-track
		background: transparent
		-webkit-border-radius: 8px
		border-radius: 8px

	&::-webkit-scrollbar-thumb
		-webkit-border-radius: 8px
		border-radius: 10px
		background: black
		border-left: 2px solid transparent
		background-clip: content-box

	&::-webkit-scrollbar-thumb
		background: $c-white-16



@mixin horizontal-list
	&:not(:last-child)::after
		content: ''
		width: 3px
		height: 3px
		border-radius: 50%
		background: currentColor
		margin: 0 6px
		display: inline-block
		vertical-align: middle

textarea
	box-sizing: initial
	width: calc(100% - 32px)!important
