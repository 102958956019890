@import '~@angular/cdk/overlay-prebuilt.css'
@import '~@angular/cdk/text-field-prebuilt.css'

@import "reset"
@import "mixins"
@import "dialog"
@import "toast"
@import "table"

body
	background: $c-ameetee-gray
	color: $c-white-100
	font-family: 'Inter', sans-serif
	font-size: 16px
	line-height: 24px

input
	&:-webkit-autofill, &:-webkit-autofill:focus
		transition: background-color 600000s 0s, color 600000s 0s
	&[data-autocompleted]
		background-color: transparent !important

//for ngx-file-upload customClassName
.upload-file
	display: flex
	align-items: center
	border: 1px dashed $c-ameetee-gray
	border-radius: 8px
	width: 496px
	height: 88px
	padding: 24px 20px

	label
		color: $c-white-80

dl
	display: flex
	gap: 24px

	dt
		+content-m-400
		color: $c-white-60

	dd
		+subheaders-l-600
		color: $c-brand
