@import "mixins"
@import 'ngx-toastr/toastr'

.toast-container.toast-bottom-center .ngx-toastr
	width: 480px

.toast-bottom-center
	bottom: 0
	right: 0
	width: 100%
	display: flex
	flex-direction: column
	align-items: center

.toast-container
	pointer-events: none
	position: fixed
	z-index: 999999

.toast-container *
	box-sizing: border-box

.toast-container .ngx-toastr
	display: flex
	align-items: center
	justify-content: space-between
	position: relative
	overflow: hidden
	margin-bottom: 32px
	padding: 16px
	border-radius: 8px
	background-position: 16px center
	background-repeat: no-repeat
	background-size: 24px
	box-shadow: 0 4px 16px rgba($c-black-main, 0.1)
	color: white
	font-weight: 500
	line-height: 1.4

.toast-error
	background: $c-system-error

.toast-success
	background: $c-system-success
